@import '../../styles/common.scss';
@import '../../styles/players.scss';


.PropheciesCanvas {

  $_numberSelectorSizePx: 160px;

  .row {
    &:first-child .square .numberSelector {
      bottom: initial;
    }
    &:last-child .square .numberSelector {
      top: initial;
    }

    .square {
      @include playerStyles('.numberSelector', 'border', '2px solid COLOR');
      @include playerStyles('.numberSelector', 'box-shadow', '0px 6px 4px COLOR40');
      @include playerStyles('.numberSelector', 'color');
      @include playerStyles('.numberSelector .option.valid', 'background', 'COLOR40');
      @include playerStyles('.winner', 'border', '4px solid COLOR');
      @include playerStyles('.doubleWinner', 'border', '4px solid COLOR');

      &:first-child .numberSelector {
        left: 0;
        transform: translateX(0);
      }
      &:last-child .numberSelector {
        left: initial;
        right: 0;
        transform: translateX(0);
      }

      .value {
        font-size: 48px;
      }

      .winner {
        border-radius: 50%;
        width: 80%;
        height: 80%;
        margin: 10%;
      }

      .doubleWinner {
        border-radius: 50%;
        width: 60%;
        height: 60%;
        margin: 20%;
      }

      .numberSelector {
        height: $_numberSelectorSizePx;
        width: $_numberSelectorSizePx;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: auto;
        font-family: $font_family_game_number;
        font-weight: bold;
        text-align: center;
        background: white;
        border-radius: 4px;
        z-index: 2000;
        position: absolute;

        .option {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 31%;
          text-align: center;
          width: 31%;
          border-radius: 50%;
          margin: 1%;

          &.valid {
            cursor: pointer;
          }
        }
      }
    }
  }

} /* .PropheciesCanvas */

