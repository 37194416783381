
@font-face {
  font-family: 'MWBD';
  src: local('MWBD'), url(./fonts/MathWithBadDrawings-Regular.woff) format('woff');
}

@font-face {
  font-family: 'MWBD';
  src: local('MWBD'), url(./fonts/MathWithBadDrawings-Bold.woff) format('woff');
  font-weight: bold;
}

