@import '../../styles/common.scss';
@import '../../styles/players.scss';

.DandelionsCanvas {
  display: flex;
  flex-direction: row-reverse;

  .compass {
    flex-basis: 33%;
    position: relative;

    @include playerStyles('.highlight', 'background', 'radial-gradient(COLOR70, #ffffff00 75%)');
  }

  .compassOverlay {
    position: absolute;
    width: 100%;
    height: 100%;

    &, & > * {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .dirN { transform: rotate(0deg); }
    .dirNE { transform: rotate(45deg); }
    .dirE { transform: rotate(90deg); }
    .dirSE { transform: rotate(135deg); }
    .dirS { transform: rotate(180deg); }
    .dirSW { transform: rotate(225deg); }
    .dirW { transform: rotate(270deg); }
    .dirNW { transform: rotate(315deg); }

    .compassPoint {
      width: 100%;
      height: 100%;
    }

    &.directionHolder {
      left: 0;
      right: 0;
      margin: auto;
      width: 18%;
      display: flex;
      align-items: center;
      transform: rotate(90deg);

      .directionHolderInner {
        position: absolute;
        width: 100%;
        margin: auto;
      }

      .compassPoint {
        display: inline-block;
        position: relative;
        left: -180%;
        width: 24px;
        height: 24px;
        background-size: contain;

        &.highlight {
          width: 48px;
          height: 48px;
          left: -210%;
        }
      }

      .touchTarget {
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        position: relative;
        left: -210%;
        width: 150%;

        &:before {
          content: '';
          float: left;
          padding-top: 100%;
        }
      }
    }
  }

  .grid {
    flex-basis: 67%;
  }
}

