@import '../../styles/common.scss';


$_target_size: 32px;


.InfoDialog {
  display: inline-block;
  position: relative;
  height: 1em;
  width: 1em;
  margin: 0 8px;

  button.target {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    min-width: $_target_size;
    min-height: $_target_size;
  }

  i {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
} /* .InfoDialog */


.InfoDialog-tooltip {
  max-width: 400px;
} /* .InfoDialog-tooltip */


.InfoDialog-element {
  max-height: 100%;
  min-width: 50%;
  display: flex;
  flex-direction: column;

  .p-dialog-header {
    background: white;
    min-height: 48px;
    padding: 4px 16px;
  }

  .p-dialog-title,
  .p-dialog-titlebar-icons {
    font-family: $font_family_sans_serif;
    font-size: 22px;
  }

  .p-dialog-titlebar {
    padding-top: $spacing_paragraph;
  }

  .p-dialog-content {
    @include page;
    overflow: auto;
    padding-bottom: $spacing_paragraph;
  }

  .buttons {
    text-align: right;

    .p-button {
      margin-left: 16px;
      margin-right: 0px;
    }
  }

}



