@import '../../styles/common.scss';


.Topnav {
  background: $color_primary;
  display: flex;
  height: $topnav_height;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

  .logo {
    max-height: 40px;
    vertical-align: middle;
    margin: 4px;
  }

  .title {
    color: white;
    font-size: 24px;
  }

  & > .left {
    align-items: center;
    display: flex;
    margin-left: 24px;
    flex: 1;
  }

  & > .right {
    justify-content: flex-end;
    margin-bottom: auto;
    margin-right: 24px;
    margin-top: auto;
    text-align: end;
  }

  .mobile {
    & .sidebarButton {
      background-color: initial;
      border: none;
    }

    & a, & a:visited {
      color: $color_primary;
    }
  }

  .sidebar a {
    display: block;
    text-align: left;
    padding: 8px;
  }

  a.nav {
    color: white;
    margin-left: 6px;
    font-family: $font_family_sans_serif;
    padding: 6px 10px;
    text-decoration: none;

    &.selected {
      background-color: $color_primary_light;
    }

    &:hover {
      background-color: $color_highlight;
    }
  }

} // .Topnav
