@import '../../styles/common.scss';
@import '../../styles/players.scss';
@import '../../components/game/GridExport.scss';
@import '../../components/game/Tabs.scss';

.NeighborsCanvas {
  .circles {
    padding: 5px;
    position: absolute;
    top: $Grid_lineWidth / 2;
    bottom: $Grid_lineWidth / 2;
    left: $Grid_lineWidth / 2;
    right: $Grid_lineWidth / 2;
  }

  .circle {
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .grid {
    position: relative;
  }

  & .tab-control .tab-content-container .tab-content {
    &.p-col-6 .row .square .value {
      font-size: 28px;
    }
  }
} /* .NeighborsCanvas */



