@import '../../styles/common.scss';


.PlayerSettingsDialog {
} /* .PlayerSettingsDialog */

.PlayerSettingsDialog-dialogElement {
  max-width: 800px;

  .p-dialog-content {
    min-height: 350px;
  }

  @include desktop {
    max-height: 80%;
    min-width: 600px;
  }
} /* .PlayerSettingsDialog-dialogElement */

