@import '../../styles/common.scss';
@import '../../styles/players.scss';

.square {

  @include playerStyles('.linkLine', 'background-color', 'COLOR');

  .linkLineHolder {
    @include noAnimate;
    transform: rotate(45deg);
    visibility: hidden;
    z-index: 1;

    .linkLine {
      @include noAnimate;
      position: relative;
      width: 4px;
      border-radius: 2px;
      height: calc(60% + 6px);
      bottom: calc(50% + 3px);
    }

    &.NE, &.SE, &.SW, &.NW {
      .linkLine {
        height: calc(85% + 6px);
        bottom: calc(71% + 3px);
      }
    }

    &.N, &.NE, &.E, &.SE, &.S, &.SW, &.W, &.NW {
      visibility: visible;
    }

    &.N  { transform: rotate(  0deg); }
    &.NE { transform: rotate( 45deg); }
    &.E  { transform: rotate( 90deg); }
    &.SE { transform: rotate(135deg); }
    &.S  { transform: rotate(180deg); }
    &.SW { transform: rotate(225deg); }
    &.W  { transform: rotate(270deg); }
    &.NW { transform: rotate(315deg); }
  }
}