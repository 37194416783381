@import '../styles/common';

.boring-page {
  width: 75%;
  margin: 0 auto;

  h1 {
    text-align: center;
  }

  img {
    max-width: 75%;
    height: auto;
    margin: 20px auto;
    text-align: center;
    display: block;
  }

}
