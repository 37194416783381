@import '../../styles/common.scss';
@import '../../styles/players.scss';
@import '../../components/game/GridExport.scss';


.Grid {
  position: relative;

  .underlay {

    .gridLines {
      @include noAnimate();
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;

      &.vertical {
        flex-direction: row;
        top: $Grid_lineWidth / 2;
        bottom: $Grid_lineWidth / 2;

        .line {
          height: 100%;
          width: $Grid_lineWidth;
        }
      }

      &.horizontal {
        flex-direction: column;
        left: $Grid_lineWidth / 2;
        right: $Grid_lineWidth / 2;

        .line {
          height: $Grid_lineWidth;
          width: 100%;
        }
      }

      .line {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.75;
      }

    }
  }

  .rows {
    padding: $Grid_lineWidth / 2;
  }

  .row {
    display: flex;

    .square {
      @include playerStyles('.value', 'color');
      @include playerStyles(
          '.highlight', 'background',
          'radial-gradient(COLOR70, #ffffff00 75%)');
      @include playerStyles(
          '.touchTarget.clickable:hover', 'background',
          'radial-gradient(COLOR70, #ffffff00 75%)');

      flex: 1 1;
      margin: 2px;
      position: relative;

      &>* {
        height: 100%;
        width: 100%;
        position: absolute;
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .overlay-intersection {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -50%;
        margin-top: -50%;
      }

      &:after {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .touchTarget {
        cursor: pointer;
        z-index: 1000;
      }
    }
  }

  .value {
    align-items: center;
    display: flex;
    font-family: $font_family_game_number;
    font-size: 42px;
    justify-content: center;
  }

} /* .Grid */

