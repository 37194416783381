@import '../../styles/common.scss';

.Environment {
  @include page;

  .environmentCard {
    white-space: pre;
  }
} /* .Environment */

