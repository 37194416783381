

.Test {
  .section1 {
    border: 20px solid gray;
    height: 200px;
    width: 300px;
  }

  .section2 {
    border: 20px solid gray;
    height: 300px;
    width: 200px;
  }

} /* .Test */
