@import '../../styles/common.scss';
@import '../../styles/players.scss';


.PlayerArea {

  .playersHolder {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;
  }

  .playerActive {
    .player:not(.active) {
      margin-top: 30px;
    }

    .player.active {
      margin-top: 0;
    }
  }

  .player {
    display: flex;
    height: 80px;
    width: 50%;

    @include playerStyles('*', 'color');
    @include playerStyles('.score', 'border', '4px solid COLOR');
    @include playerStyles('img.active', 'filter', 'drop-shadow(0 0 5px COLOR)');

    &:first-child,
    &:nth-child(3) {
      align-items: start;
    }

    &:nth-child(2),
    &:nth-child(4) {
      align-items: end;
    }

    &.player0, &.player2 {
      left: 0;
    }

    &.player1, &.player3 {
      flex-direction: row-reverse;
      right: 0;
      img {
        transform: scaleX(-1);
      }
    }

    .score {
      font-size: 30px;
      font-family: MWBD;
      font-weight: bold;
      border-radius: 50%;
      width: 2em;
      height: 2em;
      text-align: center;
    }

    .name {
      font-family: MWBD;
      font-weight: bold;
      font-size: 24px;
      padding: 0 8px;
    }

    img {
      height: 4em;
      width: 4em;
    }
  }
} /* .PlayerArea */

