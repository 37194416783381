@import '../../styles/common.scss';
@import '../../styles/media.scss';

body {
  background-color: $color_primary;
}

.App {

  ._AppBackdrop {
    display: flex;
    background-color: white;
  }

  ._AppContent {
    flex-grow: 1;
    border-left: 4px solid $color_secondary;
    border-right: 4px solid $color_secondary;
    padding: 0 1em;
    @include desktop {
      padding: 0 2em;
    }
  }

  ._AppContent > * {
    margin-bottom: 1em;
    margin-top: 1em;
    @include desktop {
      margin-bottom: 2em;
      margin-top: 2em;
    }
  }

  a {
    text-decoration: none;
  }
}

