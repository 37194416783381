@import './media.scss';


$color_1: #28536b;  // blue-sapphire
$color_2: #c2948a;  // rosy-brown
$color_3: #7ea8be;  // pewter-blue
$color_4: #f6f0ed;  // isabelline
$color_5: #bbb193;  // khaki-web


$color_primary: $color_1;
$color_primary_light: $color_3;
$color_secondary: $color_2;
$color_highlight: $color_2;
$color_warn: $color_5;

$font_size_title: 48px;
$font_size_subtitle: 32px;
$font_size_subsubtitle: 24px;
$font_size_body: 18px;
$font_size_button: 16px;
$font_size_small: 12px;

$font_family_title: MWBD;
$font_family_game_number: MWBD;
$font_family_sans_serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', sans-serif;
$font_family_serif: Georgia, 'Times New Roman', serif;
$font_family_monospace: monospace;

$spacing_section: 48px;
$spacing_paragraph: 24px;

$topnav_height: 48px;


@mixin page {
	font-family: $font_family_serif;
	font-size: $font_size_body;

  .title {
    font-family: $font_family_title;
    font-size: $font_size_title;
    color: $color_primary;
  }

  .subtitle {
    font-family: $font_family_title;
    font-size: $font_size_subtitle;
    color: $color_primary;
  }

  .subsubtitle {
    font-family: $font_family_title;
    font-size: $font_size_subsubtitle;
    color: $color_primary;
  }

  .section {
    margin: $spacing_section/2 0;
    @include desktop {
      margin: $spacing_section 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 640px) {
    font-size: $font_size_body - 2px;

    .title {
      font-size: $font_size_title - 4px;
    }

    .subtitle {
      font-size: $font_size_subtitle - 4px;
    }
  }

}


// Animations
@mixin noAnimate {
  transition: initial;
  &:after, &:before {
    transition: 250ms;
  }
}
* {
  transition: 250ms;

  &:after, &:before {
    transition: 250ms;
  }

  .noAnimate {
    @include noAnimate;
  }

  /* Selectors blacklisted from transition as it interferes with their use. */
  .p-resizable-column,
  .p-column-resizer-helper,
  .p-dialog-enter-done {
    transition: 0ms;
  }
}


// Basic content/section layout

.content .section {
  margin: $spacing_section;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing_section;
  padding-right: $spacing_section;
  max-width: 1080px;

  @media only screen and (max-width: 640px) {
    padding-left: $spacing_section - 12px;
    padding-left: $spacing_section - 12px;
  }
}

.content .section:first-child {
  margin-top: 0;
  padding-top: $spacing_section;
}

.content .section:last-child {
  margin-bottom: 0;
  padding-bottom: $spacing_section;
}

// Useful helper classes.

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.hidden {
  display: none;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.json {
  white-space: pre;
}

@mixin shadow {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.shadow {
  @include shadow
}

