@import '../styles/common.scss';

.GamePlay {
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;

  .gameMenu {
    padding-bottom: 16px;
    align-items: center;
  }

  .gameMenuButtons {
    display: flex;
    button {
      margin-right: 16px;
    }
  }

  .gameArea {
    position: relative;

    .gameCanvas {
      overflow: hidden;
      max-height: 500px;
      max-width: 500px;
      margin: auto;
    }
  }

  .log {
    .message {
      display: none;
      &:last-child {
        display: block;
      }
    }
  }
} /* .GamePlay */
