@import '../../styles/common.scss';
@import '../../styles/players.scss';
@import '../../components/game/GridExport.scss';

.tab-control {
  display: flex;

  .playerTab {
    height: 48px;
    width: 48px;
    padding: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% 0 0 50%;
    cursor: pointer;
    font-family: $font_family_game_number;
    margin: 4px 0 16px 4px;
    letter-spacing: 2px;
    text-transform: uppercase;
    @include shadow();
    border: 2px solid white;

    &.active {
      @include playerStyles(':not(&)', 'border', '2px solid COLOR');
      border: 2px solid #888888;
      position: relative;
    }

    img {
      width: 100%;
      height: 100%;
      filter: saturate(50%);
    }
  }

  .tab-content-container {
    flex-grow: 1;
    margin-left: 4px;

    .tab-content {
      @include playerStyles('.circle', 'border', '4px solid COLOR');
      @include playerStyles('.grid', 'border', '1px solid COLOR');
      @include playerStyles('.grid', 'box-shadow', 'inset 0 0 8px COLOR');

      opacity: 1;

      &.hideTab {
        width: 0;
        opacity: 0;
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
      }
    }
  }

}
