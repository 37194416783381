@import '../../styles/common.scss';
@import '../../styles/players.scss';


.SequenciumCanvas {

  .grid {

    &.gamePhase2 .square {
      @include playerStyles('.lastMove', 'background', 'radial-gradient(COLOR50, #ffffff00 75%)');
    }

    &.gamePhase3 .square {
      @include playerStyles('.maxScore', 'border', '5px solid COLOR');
      .maxScore {
        border-radius: 50%;
      }
    }
  } /* .grid */
} /* .SequenciumCanvas */

