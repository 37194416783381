@import '../../styles/common.scss';


.GameInstructionsDialog {
} /* .GameInstructionsDialog */

.GameInstructionsDialog-dialogElement {
  width: 100%;
  max-width: 600px;
  height: 80%;
  min-height: 80%;
  max-height: 80%;

  div.p-dialog-content {
    height: 100%;
    padding-bottom: 8px;
    & > * {
      height: 100%;
    }
  }

  .p-dialog-header .p-dialog-title {
    font-family: $font_family_title;
    color: $color_primary;
  }

  .Card {
    margin: 2px 0;
    min-height: 100%;

    .img {
      height: 240px;
    }
  }
} /* .GameInstructionsDialog-dialogElement */
