@import '../../styles/common.scss';
@import '../../styles/players.scss';
@import '../../styles/fonts.scss';

.LAPCanvas {
  @include page();
  font-family: MWBD;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .probe-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .probe-button {
      font-family: MWBD;
      margin-right: 5px;
      margin-top: 2px;
      height: 40px;
    }

    .probe-image {
      margin-left: 5px;
    }

    .probe-result {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .probe-result-cell {
        //min-width: 40px;
        //min-height: 40px;
        width: 40px;
        height: 40px;
        border: 3px solid gray;
        padding: 4px;
        margin: 2px;
        border-radius: 10px;
      }
    }
  }

  .GridWrapper {
    max-width: 400px;
    min-width: 300px;
    //margin: auto 0px auto 0px;
    //margin-bottom: 10px;

    .square .value {
      padding: 1px;
    }

    &.hover-probes {
      .probe {
        z-index: 1005;  // higher than the touch-target thing in the cell
        &:hover {
          cursor: pointer;
          scale: 2;
        }
      }
    }
  }

  .color-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;

    .color-button {
      &:not(:last-child) {
        margin-right: 10px;
      }
      flex-direction: column;
    }

    .color-button-image {
      width: 40px;
      height: 40px;
      //margin-right: 10px;
      //margin-top: 5px;
      //margin-bottom: 5px;
    }

    .color-button-count {
      font-size: 20px;
      font-family: MWBD;

    }
  }

  .penalty-probe {
    text-align: center;
    font-family: 'MWBD';
  }
}

.shaking {
    animation: horizontal-shaking 0.5s;

    @keyframes horizontal-shaking {
      0% { transform: translateX(0) }
      25% { transform: translateX(5px) }
      50% { transform: translateX(-5px) }
      75% { transform: translateX(5px) }
      100% { transform: translateX(0) }
    }
}

.scrolling {
  animation: backgroundScroll 10s linear infinite;

  @keyframes backgroundScroll {
    0%   {background-position: 0 0;}
    25%  {background-position: +200px +100px;}
    50%  {background-position: +300px -100px;}
    75%  {background-position: +100px -200px;}
    100% {background-position: 0 0;}
  }
}

.hide-focus {
  &:focus-visible {
    outline: none;
  }
}

.LAPGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commands {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
    font-family: MWBD;

    .command {
        margin: 5px;
        font-size: 20px;
    }
}
