@import '../../styles/common.scss';


.GameSettingsDialog {
} /* .GameSettingsDialog */

.GameSettingsDialog-dialogElement {
  max-width: 800px;

  .p-dialog-content {
    min-height: 400px;
  }

  .readOnlyMessage {
    margin-top: 12px;
    margin-bottom: 36px;
    font-style: italic;
  }

  .divider {
    height: 1px;
    background: $color_primary;
    opacity: 0.50;
    width: 80%;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
  }

  .setting {
    margin-top: 12px;

    .settingDescription {
      font-size: 14px;
    }
  }

  @include desktop {
    max-height: 80%;
  }
} /* .GameSettingsDialog-dialogElement */

