@import '../../styles/common.scss';

.Footer {
  background: $color_primary;
  color: white;

  & > .top {
    height: 8px;
    width: 100%;
    box-shadow: 0 -3px 4px rgba(0,0,0,.4);
    position: relative;
  }

  & > .content {
    display: flex;
    padding: 24px;
  }
}


