@import '../../styles/common.scss';
@import '../../styles/players.scss';

.TurningPointsCanvas {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .middle-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //margin: 0 25px;
    min-width: 75%;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .GridWrapper {


    .square {
      @include playerStyles('.winner', 'border', '4px solid COLOR');

      .winner {
        border-radius: 50%;
        width: 80%;
        height: 80%;
        margin: 10%;
      }
    }

  }

  .fish-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .fish {
      margin: auto;
      display: block;
      transition: 0ms;
      height: auto;
      width: 70%;
    }
  }

  .spinning {
    animation: spin 800ms ease-in-out forwards;

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(90deg); }
    }
  }

  .directionSelector {
    //height: $_numberSelectorSizePx;
    //width: $_numberSelectorSizePx;
    //top: 0;
    //bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    //margin: 15px auto;
    //font-family: $font_family_game_number;
    //font-weight: bold;
    text-align: center;
    background: white;
    border-radius: 4px;
    z-index: 2000;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .directions-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 80px;

      .direction-fish {
        width: 40px;
        height: 40px;
      }
    }
  }
}
