

$desktop_width: 600px;


.desktop {
  display: none;
}

@media only screen and (min-width: #{$desktop_width}) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop_width}) {
    @content;
  }
}

