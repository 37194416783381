@import '../styles/common.scss';

.GamesList {
  .gameCardWrapper {
    color: initial;

    .Card {
      height: 100%;
    }
  }
} /* .GamesList */
