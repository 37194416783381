@import './common.scss';
@use 'sass:color';
@use 'sass:selector';


$_playerColorDefault: #888888;
// https://coolors.co/ff595e-e0a500-8ac926-1982c4-6a4c93
$_playerColorA: #ff595e;  // red
$_playerColorB: #e0a500;  // yellow
$_playerColorC: #8ac926;  // green
$_playerColorD: #1982c4;  // blue
$_playerColorE: #6a4c93;  // purple


@mixin playerStyles($selector, $property, $value: 'COLOR') {

  #{$selector} {
    #{$property}: unquote(str-replace($value, 'COLOR', $_playerColorDefault));
  }

  @at-root #{selector-append(&, '.playerStyleA')} #{$selector} {
    $v1: str-replace($value, 'COLOR', $_playerColorA);
    $v2: str-replace($v1, 'HUE', #{hue($_playerColorA)});
    #{$property}: unquote($v2);
  }

  @at-root #{selector-append(&, '.playerStyleB')} #{$selector} {
    $v1: str-replace($value, 'COLOR', $_playerColorB);
    $v2: str-replace($v1, 'HUE', #{hue($_playerColorB)});
    #{$property}: unquote($v2);
  }

  @at-root #{selector-append(&, '.playerStyleC')} #{$selector} {
    $v1: str-replace($value, 'COLOR', $_playerColorC);
    $v2: str-replace($v1, 'HUE', #{hue($_playerColorC)});
    #{$property}: unquote($v2);
  }

  @at-root #{selector-append(&, '.playerStyleD')} #{$selector} {
    $v1: str-replace($value, 'COLOR', $_playerColorD);
    $v2: str-replace($v1, 'HUE', #{hue($_playerColorD)});
    #{$property}: unquote($v2);
  }

  @at-root #{selector-append(&, '.playerStyleE')} #{$selector} {
    $v1: str-replace($value, 'COLOR', $_playerColorE);
    $v2: str-replace($v1, 'HUE', #{hue($_playerColorE)});
    #{$property}: unquote($v2);
  }
}


@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}
