@import '../../styles/common.scss';
@import '../../styles/players.scss';


.avatar {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.avatarChoice {
    display: inline-block;
    cursor: pointer;
    height: 64px;
    width: 64px;
    margin-right: 12px;
    margin-top: 4px;
    margin-bottom: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.PlayerConfig {
  &.readOnly {
    .moveUp,
    .moveDown {
      display: none;
    }

    .readOnlyMessage {
      display: block;
    }
  }

  .readOnlyMessage {
    display: none;
    margin-top: 12px;
    margin-bottom: 12px;
    font-style: italic;
  }

  .playerCountSelector {
    @include shadow;
    padding: 8px;
    border-radius: 4px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    min-height: 48px;
    width: 100%;

    &:first-child {
      flex-grow: 1;
    }

    .playerCountButtons {
      display: flex;
    }

    .playerCountButtons > * {
      align-items: center;
      background-color: white;
      border-radius: 50%;
      border: 2px solid $color_primary;
      color: $color_primary;
      display: flex;
      height: 36px;
      justify-content: center;
      line-height: 100%;
      margin: 4px;
      width: 36px;

      &.clickable:hover {
        @include shadow;
        cursor: pointer;
      }

      &.selected {
        background-color: $color_primary;
        color: white;
      }
    }
  }

  .player.editable {
    .avatar {
      cursor: pointer;
    }
  }

  .playerRow {
    display: flex;
    align-items: center;
    min-height: 48px;
    width: 100%;

    > * {
      flex-shrink: 0;
      margin: 0 8px;
    }

    .spacer {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .playerName {
      flex-grow: 1;
      font-weight: bold;
      @include playerStyles('*', 'color');
      .p-inputtext {
        font-family: $font_family_serif;
        font-weight: bold;
        width: 100%;
      }
    }

    .avatar {
      height: 32px;
      width: 32px;
    }
  }

  .player {
    &.threeOrMorePlayers:first-child .moveUp {
      display: inline-block;
      visibility: hidden;
    }
    &:first-child .moveUp {
      display: none;
    }

    &.threeOrMorePlayers:last-child .moveDown {
      display: inline-block;
      visibility: hidden;
    }
    &:last-child .moveDown {
      display: none;
    }
  }

  .shareLink {
    margin: 16px 0;
  }

  .moreInfo {
    font-family: $font_family_serif;

    em {
      color: $color_secondary;
      font-style: initial;
      font-weight: bold;
    }

    .p-accordion-header-link {
      padding: 16px;
    }
    .p-accordion-header-text i {
      margin-right: 12px;
    }
  }

  .p-overlaypanel-content {
    text-align: center;
  }

} /* .PlayerConfig */

