@import '../../styles/common.scss';
@import '../../styles/players.scss';
@import '../../components/game/GridExport.scss';
@import '../../components/game/Tabs.scss';

.PaperBoxingCanvas {
  .grid {
    position: relative;
  }

  .square {
    @include playerStyles('.winner', 'border', '4px solid COLOR');

    .winner {
      border-radius: 50%;
      width: 80%;
      height: 80%;
      margin: 10%;
    }
  }

  & .tab-control .tab-content-container .tab-content {
    &.p-col-12 .row .square .value {
      font-size: 48px;
    }
    &.p-col-6 .row .square .value {
      font-size: 24px;
    }
  }
}